<template>
  <div class="" style="margin-bottom: 50px">
    <div class="accordion" id="einzel_accordionExample">
      <div class="d-none d-sm-block">
        <div class="steps">
          <progress
            id="progress"
            :value="[step == 1 ? '25' : [step == 2 ? '75' : '100']]"
            max="100"
          ></progress>
          <div class="step-item">
            <button
              class="step-button text-center"
              type="button"
              aria-expanded="true"
            >
              1
            </button>
            <div class="step-title">auswählen</div>
          </div>
          <div class="step-item">
            <button
              class="step-button text-center"
              :class="[step > 1 ? '' : 'collapsed']"
              type="button"
              :aria-expanded="[step > 1 ? 'true' : 'false']"
            >
              2
            </button>
            <div class="step-title">prüfen</div>
          </div>
          <div class="step-item">
            <button
              class="step-button text-center"
              :class="[step > 2 ? '' : 'collapsed']"
              type="button"
              :aria-expanded="[step > 2 ? 'true' : 'false']"
            >
              3
            </button>
            <div class="step-title">Anzahl</div>
          </div>
        </div>
      </div>

      <div style="display: none">{{ scanncode }}</div>

      <div class="card1" v-if="step == '1'">
        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#einzel_accordionExample"
        >
          <div class="card-body" style="padding: 0">
            <div class="card">
              <div class="card-header" v-if="!input">Wähle eine Liste...</div>
              <div class="card-body">
                <div v-if="input == ''">
                  <div class="m-1">
                    <h6 class="card-title">Picklisten der letzten 7 Tagen</h6>
                  </div>
                </div>
                <div v-if="!isLoading">
                  <div
                    class="card m-1"
                    @click="setztePickliste(pickliste.erstellt)"
                    v-for="pickliste in picklisten"
                    :key="pickliste.erstellt"
                    :class="
                      pickliste.offen > 0
                        ? 'text-white bg-secondary text-white'
                        : 'bg-success-logistik text-white'
                    "
                  >
                    <div class="card-body py-2">
                      <a class="stretched-link"></a>
                      <h6 class="card-title">Logistik</h6>
                      <h4 class="card-text">
                        Pickdatum {{ pickliste.showdatum }}
                      </h4>
                    </div>
                  </div>
                </div>
                <div v-if="isLoading">laden...</div>

                <div class="d-flex justify-content-center">
                  <img
                    v-if="input == ''"
                    class="logo my-3"
                    style="width: 96%; max-width: 130px"
                    src="@/assets/img/Baho_Markenzeichen.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card1" v-if="step == '2'">
        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#einzel_accordionExample"
        >
          <div class="card-body" style="padding: 0">
            <div class="card">
              <div class="card-header" v-if="!input">
                Scanne den Paletten-QR-Code oder wähle aus...
              </div>
              <div class="card-body" style="padding-bottom: 0">
                <div class="d-flex justify-content-around">
                  <div class="col-12 col-md-11">
                    <Form
                      @submit="submitData"
                      :validation-schema="schema"
                      v-slot="{ errors }"
                      v-if="!success"
                    >
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">
                          <font-awesome-icon
                            :icon="['fas', 'barcode']"
                            size="2xl"
                            v-if="!isLoading"
                          />

                          <font-awesome-icon
                            :icon="['fas', 'star-of-life']"
                            size="2xl"
                            spin
                            v-if="isLoading"
                          />
                        </span>
                        <Field
                          as="input"
                          name="barcode"
                          type="text"
                          class="form-control"
                          id="barcode"
                          ref="barcode"
                          autocomplete="given-name"
                          placeholder="QR-Code"
                          aria-label="QR-Code"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <small class="text-danger" v-if="errors.barcode">{{
                        errors.barcode
                      }}</small>
                    </Form>
                  </div>
                </div>
              </div>
              <div class="card-body" style="padding-top: 0">
                <div v-if="!isLoading">
                  <div
                    class="card m-1"
                    v-for="picklist in pickliste"
                    :key="picklist.id"
                    :class="
                      picklist.lagerplatzid == 0
                        ? 'text-white bg-secondary text-white'
                        : 'bg-success-logistik text-white'
                    "
                    @click="setztePickposition(picklist.bestandid, false)"
                  >
                    <div class="card-body py-2" v-if="!picklist.bemerkung">
                      <a class="stretched-link"></a>
                      <h4 class="card-title">
                        {{ picklist.name }}
                        <span class="badge bg-info" style="float: right">
                          {{ picklist.lagerplatz }}</span
                        >
                      </h4>
                      <h6 class="card-text">
                        {{ picklist.stueck }}
                        {{
                          picklist.was == "einheit"
                            ? picklist.einheitenname
                            : picklist.was == "palette"
                            ? picklist.palettenname
                            : picklist.stueckname
                        }}
                        <span class="badge bg-success" style="float: right">{{
                          picklist.charge
                        }}</span>
                      </h6>
                    </div>

                    <div class="card-body py-2" v-if="picklist.bemerkung">
                      <a class="stretched-link"></a>
                      <h4 class="card-title">
                        {{ picklist.bemerkung }}
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-center">
                  <img
                    v-if="input == ''"
                    class="logo my-3"
                    style="width: 96%; max-width: 130px"
                    src="@/assets/img/Baho_Markenzeichen.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card1" v-if="step == '3'">
        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#einzel_accordionExample"
        >
          <div
            class="card-body"
            style="padding: 0"
            v-if="!filterPickposition.bemerkung"
          >
            <div class="card">
              <div class="card-header">Prüfe die Auslagerung...</div>
              <div class="card m-1">
                <div class="card-body py-2">
                  <a class="stretched-link"></a>
                  <h4 class="card-title">
                    {{ filterPickposition.name }}
                    <span class="badge bg-info" style="float: right">
                      {{ filterLieferung.lp }}
                    </span>
                  </h4>
                  <h6 class="card-text">
                    {{ filterPickposition.stueck }}
                    {{
                      filterPickposition.was == "einheit"
                        ? filterPickposition.einheitenname
                        : filterPickposition.was == "palette"
                        ? filterPickposition.palettenname
                        : filterPickposition.stueckname
                    }}
                    <span class="badge bg-success" style="float: right">{{
                      filterLieferung.ch
                    }}</span>
                  </h6>
                </div>
              </div>
              <div>
                <div class="card-body" v-if="filterPickposition.letztecharge">
                  Charge zuletzt: {{ filterPickposition.letztecharge }}
                  <span
                    style="
                      background: #c02000;
                      color: white;
                      padding: 5px;
                      display: block;
                      text-align: center;
                    "
                    v-if="
                      filterPickposition.letztecharge.split(' (')[0] !=
                      filterLieferung.ch
                    "
                  >
                    CHARGE PRÜFEN!
                  </span>
                </div>
                <div>
                  <div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id=""
                          >{{ filterPickposition.einheitenname }} ({{
                            nummerFormatieren(stueckproeinheit)
                          }})</span
                        >
                      </div>
                      <input
                        type="number"
                        v-model="einheitenanzahl"
                        class="form-control input-zahlen"
                        @input="UpdateEinheit()"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-success"
                          type="button"
                          v-on:click="EinheitAdd()"
                        >
                          plus
                        </button>
                        <button
                          class="btn btn-danger"
                          type="button"
                          v-on:click="EinheitMinus()"
                        >
                          minus
                        </button>
                      </div>
                    </div>

                    <br />

                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id=""
                          >{{ filterPickposition.palettenname }} ({{
                            stueckpropalette
                          }})</span
                        >
                      </div>
                      <input
                        type="number"
                        v-model="palettenanzahl"
                        class="form-control input-zahlen"
                        @input="UpdatePaletten()"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-success"
                          type="button"
                          v-on:click="PalettenAdd()"
                        >
                          plus
                        </button>
                        <button
                          class="btn btn-danger"
                          type="button"
                          v-on:click="PalettenMinus()"
                        >
                          minus
                        </button>
                      </div>
                    </div>

                    <br />

                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="">{{
                          filterPickposition.stueckname
                        }}</span>
                      </div>
                      <input
                        type="number"
                        v-model="stueckanzahl"
                        class="form-control input-zahlen"
                        @input="UpdateStueck()"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-success"
                          type="button"
                          v-on:click="StueckAdd()"
                        >
                          plus
                        </button>
                        <button
                          class="btn btn-danger"
                          type="button"
                          v-on:click="StueckMinus()"
                        >
                          minus
                        </button>
                      </div>
                    </div>

                    <br />

                    <div class="input-group-append">
                      <select
                        name="produktebestandid"
                        id="produktebestandid"
                        class="form-select"
                        v-model="bestandid"
                      >
                        <option
                          v-for="lieferungen in filterPickposition.lieferungen"
                          :key="lieferungen.id"
                          :value="lieferungen.id"
                          selected="selected"
                        >
                          LD: {{ lieferungen.ld }} - {{ lieferungen.st }} St.
                          {{ lieferungen.ch ? "- Ch: " : ""
                          }}{{ lieferungen.ch }} - LP: {{ lieferungen.lp }}
                        </option>
                      </select>
                    </div>
                    <br />

                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="">Etiketten</span>
                      </div>
                      <input
                        type="number"
                        v-model="etikettenanzahl"
                        class="form-control input-zahlen"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-success"
                          type="button"
                          v-on:click="EtikettenAdd()"
                        >
                          plus
                        </button>
                        <button
                          class="btn btn-danger"
                          type="button"
                          v-on:click="EtikettenMinus()"
                        >
                          minus
                        </button>
                      </div>
                    </div>
                    <br />
                    <div class="card-body">
                      <button
                        type="button"
                        class="btn btn-outline-success"
                        v-on:click="Ausbuchen()"
                        v-if="
                          bestellenwasinfo &&
                          this.stueckanzahl <= this.filterLieferung.st
                        "
                      >
                        {{ bestellenwasinfo }} <br />ausbuchen
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-warning"
                        style="float: right"
                        v-on:click="Ausbuchen(etikettenanzahl)"
                        v-if="
                          bestellenwasinfo &&
                          this.stueckanzahl <= this.filterLieferung.st &&
                          einheitenanzahl > 0
                        "
                      >
                        {{ bestellenwasinfo }} <br />ausbuchen <br />mit
                        {{ etikettenanzahl }} ETIKETTEN
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="card-body"
            style="padding: 0"
            v-if="
              filterPickposition.bemerkung && filterPickposition.bemerkung != ''
            "
          >
            <div class="card">
              <div class="card-header">Info/Sonstiges</div>
              <div class="card m-1">
                <div class="card-body py-2">
                  <a class="stretched-link"></a>
                  <h4 class="card-title">
                    {{ filterPickposition.bemerkung }}
                  </h4>
                </div>
              </div>

              <div class="card-body">
                <button
                  type="button"
                  class="btn btn-success"
                  v-on:click="Ausbuchen(0)"
                  v-if="filterPickposition.lagerplatzid == 0"
                >
                  erledigt
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav class="navbar fixed-bottom navbar-light bg-light justify-content-end">
    <div class="col-12 col-lg-9">
      <div class="d-flex justify-content-around">
        <b-button @click="step--" :disabled="step < 2" style="width: 110px"
          ><font-awesome-icon :icon="['fass', 'forward']" rotation="180" />
          zurück</b-button
        >
        <b-button
          variant="danger"
          v-on:click="abbrechenZeigen()"
          style="width: 110px"
          >abbrechen</b-button
        >
        <b-button
          variant="success"
          @click="step++"
          :disabled="true"
          style="width: 110px"
          >weiter <font-awesome-icon :icon="['fass', 'forward']"
        /></b-button>
      </div>
    </div>
  </nav>
</template>

<script>
//import { mapGetters } from "vuex";
import Store from "@/store";
import { Form, Field } from "vee-validate";
import * as yup from "yup";
import { uuid } from "vue-uuid";
import axios from "axios";
//import dayjs from "dayjs";
//import { useSound } from "@vueuse/sound";
//import soundError from "@/assets/sounds/error.mp3";

//import { log } from "logrocket";
import { useToast } from "vue-toastification";

export default {
  name: "TheLogistikInfo",
  components: {
    Form,
    Field,
    //...mapGetters({ cartSum: "cartTotal" }), // wenn auf andere Variable übergeben wird
  },
  data() {
    const schema = yup.object().shape({
      barcode: yup
        .string()
        .required("Ein Barcode/Name wird benötigt.")
        .trim()
        .min(3, "Der Barcode/Name muss >3 Zeichen lang sein!"),
    });
    return {
      step: 1,
      schema,
      error: "",
      isLoading: false,
      success: false,

      invitee: "",
      mode: "",
      input: "",
      pickdatum: "",
      produktdaten: [],
      picklisten: [], // Datum
      pickliste: [
        {
          lagerplatz: "E321",
          bestandid: "13933",
          charge: "",
          lieferungen: {
            13933: {
              lp: "E321",
              st: "1000",
              ld: "06.03.2024",
              id: "13933",
            },
            13934: {
              lp: "A221",
              st: "5000",
              ld: "06.03.2024",
              id: "13934",
            },
            13935: {
              lp: "A123",
              st: "5000",
              ld: "06.03.2024",
              id: "13935",
            },
          },
          produktid: "234",
          id: "234",
          stueck: "10",
          was: "einheit",
          name: "Teebeutel 190x100x400",
          stueckname: "Stueck",
          einheitenname: "Karton",
          palettenname: "Palette",
          stueckproeinheit: "250.00",

          einheitenanzahl: null,
          stueckpropalette: "20",
          lagerplatzid: "0",
        },
      ],
      pickposition: [],
      einheitenanzahl: 0,
      palettenanzahl: 0,
      stueckanzahl: 0,
      stueckproeinheit: 0,
      stueckpropalette: 0,
      bestandid: 0,
      etikettenanzahl: 0,

      toast: useToast(),
    };
  },
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  computed: {
    rrID() {
      return Store.getters.activeRREvent();
    },

    searchData() {
      return this.search;
    },
    scanncode() {
      console.log("scanncodeaufruf....");
      var scanncode_akt = Store.getters.activeBarcode();
      Store.mutations.setBarcode([]);
      if (scanncode_akt.org) {
        // Manuelle Sucheliste leeren
        console.log("GP scanncode aufruf!", scanncode_akt, this.step);
        if (this.step == 2) {
          if (scanncode_akt.org.split("/")[0] == "pbid") {
            this.setztePickposition(scanncode_akt.org.split("/")[1]);
          } else {
            // Barcode falsch
            this.toast.error("Barcode an dieser Stelle nicht möglich!");
            //Store.mutations.setBarcode([]);
            scanncode_akt = [];
          }
        }
      }
      return scanncode_akt;
    },
    filterPickposition() {
      // Prüfe ob die Pickposition vorhanden ist
      let check = this.pickliste.filter(
        (item) => item.bestandid === this.pickposition
      );
      if (typeof check[0] === "undefined") {
        console.log(
          "nix gefunden, suche in den Lieferungen...",
          this.pickposition
        );
        for (let i in this.pickliste) {
          //console.log('suche in ',this.pickliste[i].lieferungen);
          if (this.pickliste[i].lieferungen[this.pickposition]) {
            console.log("gefunden", this.pickliste[i]);
            this.setzeBestandid(this.pickposition);
            this.setzePickposition(this.pickliste[i].bestandid);
            return this.pickliste[i];
          }
        }

        return false;
      } else {
        this.setzeBestandid(this.pickposition);
        return this.pickliste.filter(
          (item) => item.bestandid === this.pickposition
        )[0];
      }
    },
    filterLieferung() {
      return this.pickliste.filter(
        (item) => item.bestandid === this.pickposition
      )[0].lieferungen[this.bestandid];
    },
  },

  methods: {
    setzeBestandid(id) {
      console.log("setzeBestandid", id);
      this.bestandid = id;
    },
    setzePickposition(id) {
      console.log("setzePickposition", id);
      this.pickposition = id;
    },
    nummerFormatieren(x, stellen = 0) {
      return Number.parseFloat(x).toFixed(stellen);
    },
    holePicklisten(id) {
      this.isLoading = true;
      axios
        .get(
          "https://bahnhof-logistik.pani-verwaltung.de/module/api_logistik.php" +
            "?picklisten=" +
            id +
            "&offen=1" +
            "&user=" +
            Store.getters.activeGeraet() +
            "&uuid=" +
            uuid.v1()
        )
        .then((response) => {
          console.log(response.data.status);

          this.isLoading = false;

          if (response.data.status == "0") {
            console.log("fertig geholt...");
            if (id == 1) {
              this.picklisten = response.data.data;
              console.log("Picklisten", this.picklisten);
            } else {
              this.pickliste = response.data.data;
              console.log("Pickliste", this.pickliste);
            }
          }
          return false;
        })
        .catch((error) => {
          this.isLoading = false;
          throw new Error(error);
        });
    },

    UpdateEinheit() {
      console.log("update Einheit", this.einheitenanzahl);
      if (this.einheitenanzahl < 0) {
        this.einheitenanzahl = 0;
      }
      this.stueckanzahl = this.einheitenanzahl * this.stueckproeinheit;
      this.bestellenwas = "einheit";
      this.bestellenstueck = this.einheitenanzahl;
      this.bestellenwasinfo = this.einheitenanzahl + " " + this.einheitenname;

      this.palettenanzahl = Math.floor(
        this.stueckanzahl / this.stueckproeinheit / this.stueckpropalette
      );

      this.etikettenanzahl = this.einheitenanzahl;
    },

    EinheitAdd() {
      if (!isNaN(this.einheitenanzahl)) {
        this.einheitenanzahl = this.einheitenanzahl + 1;

        this.UpdateEinheit();
      } else {
        this.einheitenanzahl = 0;
        this.UpdateEinheit();
      }
    },

    EinheitMinus() {
      if (!isNaN(this.einheitenanzahl)) {
        if (this.einheitenanzahl > 0) {
          this.einheitenanzahl = this.einheitenanzahl - 1;

          this.UpdateEinheit();
        }
      } else {
        this.einheitenanzahl = 0;

        this.UpdateEinheit();
      }
    },
    EinheitCheck() {
      console.log(this.einheitenanzahl);
      //this.einheitenanzahl= this.einheitenanzahl - 1;
    },

    UpdatePaletten() {
      console.log("update Paletten ", this.palettenanzahl);

      this.stueckanzahl =
        this.palettenanzahl * (this.stueckproeinheit * this.stueckpropalette);
      this.bestellenwas = "palette";
      this.bestellenstueck = this.palettenanzahl;
      this.bestellenwasinfo = this.palettenanzahl + " " + this.palettenname;

      this.einheitenanzahl = this.stueckanzahl / this.stueckproeinheit;

      this.etikettenanzahl = this.einheitenanzahl;
    },

    PalettenAdd() {
      if (!isNaN(this.palettenanzahl)) {
        this.palettenanzahl = this.palettenanzahl + 1;

        this.UpdatePaletten();
      } else {
        this.palettenanzahl = 0;

        this.UpdatePaletten();
      }
    },

    PalettenMinus() {
      if (!isNaN(this.palettenanzahl)) {
        if (this.palettenanzahl > 0) {
          this.palettenanzahl = this.palettenanzahl - 1;
          this.UpdatePaletten();
        }
      } else {
        this.palettenanzahl = 0;

        this.UpdatePaletten();
      }
    },

    PalettenCheck() {
      console.log(this.palettenanzahl);
      //this.einheitenanzahl= this.einheitenanzahl - 1;
    },

    UpdateStueck() {
      console.log("update Stück ", this.stueckanzahl);
      this.palettenanzahl = Math.floor(
        this.stueckanzahl / this.stueckproeinheit / this.stueckpropalette
      );
      this.bestellenwas = "stueck";
      this.bestellenstueck = this.stueckanzahl;
      this.bestellenwasinfo = this.stueckanzahl + "  Stück";

      this.einheitenanzahl = Math.floor(
        this.stueckanzahl / this.stueckproeinheit
      );

      this.etikettenanzahl = this.einheitenanzahl;
    },

    StueckAdd() {
      if (!isNaN(this.stueckanzahl)) {
        this.stueckanzahl = this.stueckanzahl + 1;

        this.UpdateStueck();
      } else {
        this.stueckanzahl = 0;

        this.UpdateStueck();
      }
    },

    StueckMinus() {
      if (!isNaN(this.stueckanzahl)) {
        if (this.stueckanzahl > 0) {
          this.stueckanzahl = this.stueckanzahl - 1;

          this.UpdateStueck();
        }
      } else {
        this.stueckanzahl = 0;

        this.UpdateStueck();
      }
    },

    StueckCheck() {
      console.log(this.einheitenanzahl);
      //this.einheitenanzahl= this.einheitenanzahl - 1;
    },

    EtikettenAdd() {
      if (!isNaN(this.etikettenanzahl)) {
        this.etikettenanzahl = this.etikettenanzahl + 1;

        //this.UpdateEtiketten();
      } else {
        this.etikettenanzahl = 0;

        //this.UpdateEtiketten();
      }
    },

    EtikettenMinus() {
      if (!isNaN(this.etikettenanzahl)) {
        if (this.etikettenanzahl > 0) {
          this.etikettenanzahl = this.etikettenanzahl - 1;

          // this.UpdateEtiketten();
        }
      } else {
        this.etikettenanzahl = 0;

        //this.UpdateEtiketten();
      }
    },

    setztePickliste(pickliste) {
      console.log("setztePickliste", pickliste);
      this.pickdatum = pickliste;
      this.holePicklisten(pickliste);
      this.step++;
    },
    setztePickposition(bestandid, scanner = true) {
      this.pickposition = bestandid;
      console.log(
        "setztePickposition",
        scanner,
        bestandid,
        this.filterPickposition
      );

      if (
        typeof this.filterPickposition === "undefined" ||
        this.filterPickposition === null ||
        this.filterPickposition === false
      ) {
        this.toast.error("Pickposition nicht gefunden!");
      } else {
        //this.pickposition = bestandid;
        //this.holePicklisten(bestandid);

        this.einheitenanzahl = 0;
        this.palettenanzahl = 0;
        this.stueckanzahl = 0;
        this.stueckproeinheit = 0;
        this.stueckpropalette = 0;

        this.stueckname = this.filterPickposition.stueckname;
        this.einheitenname = this.filterPickposition.einheitenname;
        this.palettenname = this.filterPickposition.palettenname;
        this.stueckproeinheit = parseInt(
          this.filterPickposition.stueckproeinheit
        );
        this.stueckpropalette = parseInt(
          this.filterPickposition.stueckpropalette
        );

        this.bestellenwas = this.filterPickposition.was;

        if (this.filterPickposition.was == "stueck") {
          this.stueckanzahl = parseInt(this.filterPickposition.stueck);
          this.UpdateStueck();
        } else if (this.filterPickposition.was == "einheit") {
          this.stueckanzahl =
            parseInt(this.filterPickposition.stueck) * this.stueckproeinheit;
          this.einheitenanzahl = parseInt(this.filterPickposition.stueck);
          this.UpdateEinheit();
        } else if (this.filterPickposition.was == "palette") {
          this.stueckanzahl =
            parseInt(this.filterPickposition.stueck) * this.stueckpropalette;
          this.palettenanzahl = parseInt(this.filterPickposition.stueck);
          this.UpdatePaletten();
        }

        if (!scanner) {
          this.bestandid = this.filterPickposition.bestandid;
        }

        if (this.filterPickposition.lagerplatz != "") {
          this.step++;
        } else {
          this.toast.error(
            "Lagerplatz nicht mehr gefunden, schon ausgelagert?"
          );
        }
      }
    },
    submitData(values) {
      this.isLoading = true;
      this.success = false;
      this.error = "";
      console.log(values);
      this.input = values.barcode;
      //this.invitee = uuid.v1();
      this.holePersonen();
    },
    focusInput() {
      if (this.searchData) {
        this.$refs.barcode.$el.value = this.searchData;
        this.input = this.searchData;
        this.holePersonen();
      } else {
        this.$refs.barcode.$el.focus();
      }
    },
    modusUpdate(event) {
      console.log(event);
      this.$emit("modus-updated", event);
      this.mode = event;
    },
    Ausbuchen(drucken = 0) {
      console.log("Ausbuchen start", drucken);
      //alert("hier würde ich nun " + this.stueckanzahl + " von "+ this.bestandid +" ausbuchen...");

      this.pickliste.forEach((pick) => {
        if (pick.bestandid === this.pickposition) {
          pick.lagerplatzid = this.bestandid;
        }
      });

      this.isLoading = true;
      axios
        .get(
          "https://bahnhof-logistik.pani-verwaltung.de/module/api_logistik.php" +
            "?pick=" +
            this.filterPickposition.id +
            "&lagerplatzid=" +
            this.bestandid +
            "&stueck=" +
            this.stueckanzahl +
            "&drucken=" +
            drucken +
            "&user=" +
            Store.getters.activeGeraet() +
            "&uuid=" +
            uuid.v1()
        )
        .then((response) => {
          console.log(response.data.status);

          this.isLoading = false;

          if (response.data.status == "0") {
            console.log("fertig gesendet...");
            if (response.data.abbuchen && response.data.abbuchen != "") {
              this.toast.success(response.data.abbuchen);
            }
            if (response.data.drucken && response.data.drucken != "") {
              this.toast.success(response.data.drucken);
            }
            this.holePicklisten(this.pickdatum);
          }
          return false;
        })
        .catch((error) => {
          this.isLoading = false;
          throw new Error(error);
        });

      this.step--;
    },
    setBarcode(id) {
      //if (check) {
      this.barcode = id;
      console.log("setBarcode ", this.barcode);
      this.holeProduktInfo(id);
      //this.holePerson();
      //this.step = 2;
      //} else {
      //  console.log(check);
      //}
    },
    abbrechenZeigen() {
      //if (this.sammelTLNs.length > 0) {
      //  this.listeAbbrechen = true;
      window.scrollTo(0, 0);
      //} else {
      if (this.step > 1) {
        this.step--;
        console.log("abbrechenZeigen step--");
        //Store.mutations.deleteAlleSammelTLNs();
      } else if (this.step > 0) {
        this.modusUpdate({ newModus: "0" });
      }

      //}
    },
  },
  emits: {
    "modus-updated": (modusDO) => {
      if ("newModus" in modusDO === false) {
        console.warn(
          "ModusCardComponent: Jede Button muss ein Modus-Attribut haben."
        );
        return false;
      }
      return true;
    },
  },
  mounted() {
    //this.focusInput();
    this.holePicklisten(1);
  },
};
</script>

<style scoped>
.bg-success-logistik {
  background-color: rgba(45 104 48);
}
</style>
