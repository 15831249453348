<template>
  <div class="" style="margin-bottom: 50px">
    <div class="accordion" id="einzel_accordionExample">
      <div class="d-none d-sm-block">
        <div class="steps">
          <progress
            id="progress"
            :value="[step == 1 ? '25' : [step == 2 ? '75' : '100']]"
            max="100"
          ></progress>
          <div class="step-item">
            <button
              class="step-button text-center"
              type="button"
              aria-expanded="true"
            >
              1
            </button>
            <div class="step-title">scannen</div>
          </div>
          <div class="step-item">
            <button
              class="step-button text-center"
              :class="[step > 1 ? '' : 'collapsed']"
              type="button"
              :aria-expanded="[step > 1 ? 'true' : 'false']"
            >
              2
            </button>
            <div class="step-title">prüfen</div>
          </div>
          <div class="step-item">
            <button
              class="step-button text-center"
              :class="[step > 2 ? '' : 'collapsed']"
              type="button"
              :aria-expanded="[step > 2 ? 'true' : 'false']"
            >
              3
            </button>
            <div class="step-title">Startnr</div>
          </div>
        </div>
      </div>

      <div style="display: none">{{ scanncode }}</div>
      <div class="card1" v-if="produktdaten.status == 0">
        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#einzel_accordionExample"
        >
          <div class="card-body" style="padding: 0">
            <div class="card">
              <div class="card-header">
                {{ produktdaten.name }}
              </div>
              <div>
                <div class="card-body">
                  Aktueller Bestand:
                  {{ nummerFormatieren(produktdaten.bestandgesamt) }}
                </div>
                <div>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Wo</th>
                        <th>Lieferscheinnr</th>
                        <th>Charge</th>
                        <th>Stück</th>
                      </tr>
                    </thead>
                    <tbody v-if="produktdaten.bestand">
                      <tr
                        v-for="bestandsliste in produktdaten.bestand"
                        :key="bestandsliste.id"
                      >
                        <td>{{ bestandsliste.platztxt }}</td>
                        <td>{{ bestandsliste.lieferscheinnr }}</td>
                        <td>{{ bestandsliste.charge }}</td>
                        <td>{{ nummerFormatieren(bestandsliste.stueck) }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <button
                    type="button"
                    class="btn btn-outline-success"
                    v-on:click="WechsleZuPickliste(produktdaten.nr)"
                    v-if="produktdaten.bestand"
                  >
                    Pickliste hinzufügen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card1" v-if="step == '1' && produktdaten.status != 0">
        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#einzel_accordionExample"
        >
          <div class="card-body" style="padding: 0">
            <div class="card">
              <div class="card-header" v-if="!input">Scanne den QR-Code...</div>
              <div class="card-body">
                <div class="d-flex justify-content-around">
                  <div class="col-12 col-md-11">
                    <Form
                      @submit="submitData"
                      :validation-schema="schema"
                      v-slot="{ errors }"
                      v-if="!success"
                    >
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">
                          <font-awesome-icon
                            :icon="['fas', 'barcode']"
                            size="2xl"
                            v-if="!isLoading"
                          />

                          <font-awesome-icon
                            :icon="['fas', 'star-of-life']"
                            size="2xl"
                            spin
                            v-if="isLoading"
                          />
                        </span>
                        <Field
                          as="input"
                          name="barcode"
                          type="text"
                          class="form-control"
                          id="barcode"
                          ref="barcode"
                          autocomplete="given-name"
                          placeholder="QR-Code"
                          aria-label="QR-Code"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <small class="text-danger" v-if="errors.barcode">{{
                        errors.barcode
                      }}</small>

                      <div v-if="input != ''">
                        <div class="list-group">
                          <a
                            v-for="eintrag in suchenliste"
                            :key="eintrag.bib"
                            href="#"
                            @click="setBarcode(eintrag.bib)"
                            class="list-group-item list-group-item-action d-flex justify-content-between align-items-start px-0"
                          >
                            <div class="container">
                              <div class="row">
                                <div class="col px-2">
                                  <span class="fw-bold">
                                    {{ eintrag.name }}
                                  </span>
                                  <span
                                    class="badge bg-success"
                                    style="float: right"
                                    >{{
                                      eintrag.sbib == ""
                                        ? eintrag.bib
                                        : eintrag.sbib
                                    }}</span
                                  >
                                </div>
                              </div>
                              <div class="row">
                                <div class="col p-0 ps-2">
                                  {{ eintrag.contest }}
                                </div>
                                <div class="col text-right p-0 pe-2"></div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>

                <div v-if="input == ''">
                  <div class="m-1">
                    <h6 class="card-title">Logistik</h6>
                  </div>
                </div>

                <div v-if="input == ''">
                  <div class="card m-1 bg-success-logistik text-white">
                    <div class="card-body py-2">
                      <a class="stretched-link"></a>
                      <h6 class="card-title">Logistik</h6>
                      <h4 class="card-text">Info</h4>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-center">
                  <img
                    v-if="input == ''"
                    class="logo my-3"
                    style="width: 96%; max-width: 130px"
                    src="@/assets/img/Baho_Markenzeichen.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav class="navbar fixed-bottom navbar-light bg-light justify-content-end">
    <div class="col-12 col-lg-9">
      <div class="d-flex justify-content-around">
        <b-button @click="step--" :disabled="step < 2" style="width: 110px"
          ><font-awesome-icon :icon="['fass', 'forward']" rotation="180" />
          zurück</b-button
        >
        <b-button
          variant="danger"
          v-on:click="abbrechenZeigen()"
          style="width: 110px"
          >abbrechen</b-button
        >
        <b-button
          variant="success"
          @click="step++"
          :disabled="true"
          style="width: 110px"
          >weiter <font-awesome-icon :icon="['fass', 'forward']"
        /></b-button>
      </div>
    </div>
  </nav>
</template>

<script>
//import { mapGetters } from "vuex";
import Store from "@/store";
import { Form, Field } from "vee-validate";
import * as yup from "yup";
import { uuid } from "vue-uuid";
import axios from "axios";
//import dayjs from "dayjs";
//import { useSound } from "@vueuse/sound";
//import soundError from "@/assets/sounds/error.mp3";

//import { log } from "logrocket";
import { useToast } from "vue-toastification";

export default {
  name: "TheLogistikInfo",
  components: {
    Form,
    Field,
    //...mapGetters({ cartSum: "cartTotal" }), // wenn auf andere Variable übergeben wird
  },
  data() {
    const schema = yup.object().shape({
      barcode: yup
        .string()
        .required("Ein Barcode/Name wird benötigt.")
        .trim()
        .min(3, "Der Barcode/Name muss >3 Zeichen lang sein!"),
    });
    return {
      step: 1,
      schema,
      error: "",
      isLoading: false,
      success: false,

      invitee: "",
      mode: "",
      input: "",
      suchenliste: [],

      produktdaten: [],

      toast: useToast(),
    };
  },
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  computed: {
    rrID() {
      return Store.getters.activeRREvent();
    },

    searchData() {
      return this.search;
    },
    scanncode() {
      console.log("scanncodeaufruf....");
      var scanncode_akt = Store.getters.activeBarcode();
      Store.mutations.setBarcode([]);
      if (scanncode_akt.org) {
        // Manuelle Sucheliste leeren
        this.suchlisteLeeren();
        console.log("GP scanncode aufruf!", scanncode_akt, this.step);
        if (this.step == 1) {
          if (scanncode_akt.type == "#") {
            this.setBarcode(scanncode_akt.gsplit);

            //Store.mutations.setBarcode([]);
            scanncode_akt = [];
          } else {
            // Barcode falsch
            this.toast.error("Barcode an dieser Stelle nicht möglich!");
            //Store.mutations.setBarcode([]);
            scanncode_akt = [];
          }
        }
      }
      return scanncode_akt;
    },
  },

  methods: {
    nummerFormatieren(x, stellen = 0) {
      return Number.parseFloat(x).toFixed(stellen);
    },
    holeProduktInfo(nr) {
      axios
        .get(
          "https://bahnhof-logistik.pani-verwaltung.de/module/api_logistik.php" +
            "?nr=" +
            nr +
            "&uuid=" +
            uuid.v1()
        )
        .then((response) => {
          console.log(response.data.status);

          this.isLoading = false;

          if (response.data.status == "0") {
            console.log("fertig geholt...");
            this.produktdaten = response.data;
            console.log("Response Data", response.data);
            console.log("Prdouktdaten", this.produktdaten);
          }
          return false;
        })
        .catch((error) => {
          this.isLoading = false;
          throw new Error(error);
        });
    },

    suchlisteLeeren() {
      // Manuelle Sucheliste leeren
      console.log("suchlisteLeeren");
      this.input = "";
      this.suchenliste = [];
    },

    WechsleZuPickliste(nr) {
      console.log("WechsleZuPickliste", nr);

      let event = "Liste";
      this.$emit("modus-updated", { newModus: event });
      this.mode = event;

      let scanner_code = {
        type: "#",
        org: "#" + nr,
        gsplit: nr,
      };

      Store.mutations.setBarcode(scanner_code);
    },

    submitData(values) {
      this.isLoading = true;
      this.success = false;
      this.error = "";
      console.log(values);
      this.input = values.barcode;
      //this.invitee = uuid.v1();
      this.holePersonen();
    },
    focusInput() {
      if (this.searchData) {
        this.$refs.barcode.$el.value = this.searchData;
        this.input = this.searchData;
        this.holePersonen();
      } else {
        this.$refs.barcode.$el.focus();
      }
    },
    modusUpdate(event) {
      console.log(event);
      this.$emit("modus-updated", event);
      this.mode = event;
    },
    setBarcode(id) {
      //if (check) {
      this.barcode = id;
      console.log("setBarcode ", this.barcode);
      this.holeProduktInfo(id);
      //this.holePerson();
      //this.step = 2;
      //} else {
      //  console.log(check);
      //}
    },
    abbrechenZeigen() {
      //if (this.sammelTLNs.length > 0) {
      //  this.listeAbbrechen = true;
      window.scrollTo(0, 0);
      //} else {
      if (this.step > 1) {
        this.step--;
        console.log("abbrechenZeigen step--");
        //Store.mutations.deleteAlleSammelTLNs();
      } else if (this.step > 0) {
        this.modusUpdate({ newModus: "0" });
      }

      //}
    },
  },
  emits: {
    "modus-updated": (modusDO) => {
      if ("newModus" in modusDO === false) {
        console.warn(
          "ModusCardComponent: Jede Button muss ein Modus-Attribut haben."
        );
        return false;
      }
      return true;
    },
  },
  mounted() {
    //this.focusInput();
  },
};
</script>

<style scoped>
.bg-success-logistik {
  background-color: rgba(45 104 48);
}
</style>
