<template>
  <div class="card-body m-1" style="padding: 0">
    <div class="card bg-success-logistik">
      <div
        class="card-header text-white fw-bold fst-italic"
        style="font-size: 150%"
      >
        Logistik
      </div>
    </div>
  </div>

  <div class="border border-secondary rounded m-1 mt-4">
    <div
      class="card m-2"
      :class="[
        mode == 'Info'
          ? 'bg-success-logistik text-white'
          : 'text-white bg-secondary',
      ]"
    >
      <div class="card-body py-2">
        <a v-on:click="onSelect('Info')" class="stretched-link"></a>
        <h6 class="card-title">Logistik</h6>
        <h4 class="card-text">Info</h4>
      </div>
    </div>
    <div
      class="card m-2"
      :class="[
        mode == 'Liste'
          ? 'bg-success-logistik text-white'
          : 'text-white bg-secondary',
      ]"
    >
      <div class="card-body py-2">
        <a v-on:click="onSelect('Liste')" class="stretched-link"></a>
        <h6 class="card-title">Logistik</h6>
        <h4 class="card-text">Pickliste erstellen</h4>
      </div>
    </div>
    <div
      class="card m-2"
      :class="[
        mode == 'Picken'
          ? 'bg-success-logistik text-white'
          : 'text-white bg-secondary',
      ]"
    >
      <div class="card-body py-2">
        <a v-on:click="onSelect('Picken')" class="stretched-link"></a>
        <h6 class="card-title">Logistik</h6>
        <h4 class="card-text">Pickliste anzeigen</h4>
      </div>
    </div>

    <div
      class="card m-2"
      :class="[
        mode == 'Umlagern'
          ? 'bg-success-logistik text-white'
          : 'text-white bg-secondary',
      ]"
    >
      <div class="card-body py-2">
        <a v-on:click="onSelect('Umlagern')" class="stretched-link"></a>
        <h6 class="card-title">Logistik</h6>
        <h4 class="card-text">Ware umlagern</h4>
      </div>
    </div>
  </div>

  <nav class="navbar fixed-bottom navbar-light bg-light justify-content-end">
    <div class="col-12 col-lg-9">
      <div class="d-flex justify-content-around">
        <img
          class="logo"
          src="@/assets/img/Haus_reduziert.svg"
          style="max-width: 100px"
        />
      </div>
    </div>
  </nav>
</template>

<script>
//import { mapGetters } from "vuex";
//import { Form, Field } from "vee-validate";

export default {
  name: "TheSelectionLogistik",
  components: {
    //Form,
    //Field,
    //...mapGetters({ cartSum: "cartTotal" }), // wenn auf andere Variable übergeben wird
  },
  data() {
    return {
      mode: "",
      //vorauswahl: true,
    };
  },
  computed: {
    //...mapGetters({ cartSum: "cartTotal" }), // wenn auf andere Variable übergeben wird
  },
  emits: {
    "modus-updated": (modusDO) => {
      if ("newModus" in modusDO === false) {
        console.warn(
          "ModusCardComponent: Jede Button muss ein Modus-Attribut haben."
        );
        return false;
      }
      return true;
    },
  },
  methods: {
    onSelect(event) {
      //this.vorauswahl = false;
      console.log(event);
      this.$emit("modus-updated", { newModus: event });
      this.mode = event;
    },
  },
};
</script>

<style scoped>
.card-header {
  border-bottom: none;
}
.bg-success-logistik {
  background-color: rgba(45 104 48);
}
</style>
