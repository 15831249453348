<template>
  <div id="app">
    <TheNavbar
      :options="{ updateExists: updateExists }"
      @modus-updated="modusStatus"
    ></TheNavbar>
    <div class="container-fluid" style="--bs-gutter-x: 0; margin-top: 56px">
      <div class="row">
        <div
          class="col-12 col-lg-3"
          :class="[mode === '0' ? '' : 'd-none d-sm-block']"
        >
          <TheLogin
            v-if="activeview == 'Login'"
            @modus-updated="modusStatus"
          ></TheLogin>

          <TheSelectionLogistik
            v-if="activeview == 'Startkontrolle'"
            @modus-updated="modusStatus"
          ></TheSelectionLogistik>
        </div>
        <div class="col-12 col-lg-9">
          <div class="m-1" v-if="activeview == 'Startkontrolle'">
            <TheLogistikInfo
              v-if="mode == 'Info'"
              @modus-updated="modusStatus"
            ></TheLogistikInfo>
            <TheLogistikListe
              v-if="mode == 'Liste'"
              @modus-updated="modusStatus"
            ></TheLogistikListe>
            <TheLogistikPicken
              v-if="mode == 'Picken'"
              @modus-updated="modusStatus"
            ></TheLogistikPicken>
            <TheLogistikUmlagern
              v-if="mode == 'Umlagern'"
              @modus-updated="modusStatus"
            ></TheLogistikUmlagern>
          </div>
        </div>
      </div>
    </div>

    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasExample"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExampleLabel">Info</h5>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="p-3 pt-0">
          <h3 v-if="appEventName">{{ appEventName }}</h3>
          <span v-if="appEventName"
            >vom {{ appEventVon }} bis {{ appEventBis }} <br
          /></span>

          <br />
          <br />
          <br />
          <select
            v-model="geraet"
            class="form-select"
            :class="[geraet != '' ? 'status_gruen' : 'bg-warning']"
            :selected="geraet == ''"
            @change="set_geraet($event)"
            style="font-size: 1.1em"
            v-if="geraet == ''"
          >
            <option
              v-for="option in scanner_vals"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
          <!--Debug: <br />{{ this.browser }}<br />
          <button @click="play" class="btn btn-info my-2">Beep</button><br />
          {{ scanncode.type }}<br /><br />-->

          <button @click="closeButton()" class="btn btn-danger my-2">
            App schließen</button
          ><br />

          <button @click="reloadButton()" class="btn btn-warning my-2">
            App reload</button
          ><br />
          Version: 091224-1805<br />
          <span v-if="geraet">Gerät: {{ geraete_name }}<br /></span>

          <button
            @click="refreshApp"
            v-if="updateExists"
            class="btn btn-success my-2"
          >
            Neue Version vorhanden! <br />Klicke für Update
          </button>

          <p class="decode-result" v-if="QRScannerEnabled">
            Letzter scann:
            <b>{{ QRScannerResult }}</b>

            <qrcode-stream
              :paused="QRScannerPaused"
              @detect="onDetect"
              @error="onError"
              @camera-on="resetValidationState"
            >
            </qrcode-stream>
          </p>
          <button
            @click="QRScannerEnabled = true"
            v-if="!QRScannerEnabled"
            class="btn btn-success my-2"
          >
            Kamera aktivieren
          </button>
          <br />
          <br />
          <br />

          <button
            @click="logoutButton()"
            class="btn btn-danger my-2"
            v-if="appEventName"
          >
            APP LOGOUT</button
          ><br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import LogRocket from "logrocket";
//LogRocket.init("vjfwqa/checkin-app");

// This is an example script - don't forget to change it!
//LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
//  name: 'James Morrison',
//  email: 'jamesmorrison@example.com',

// Add your own custom user variables here, ie:
//  subscriptionType: 'pro'
//});

import Store from "@/store";
import TheNavbar from "@/components/TheNavbar.vue";

import TheLogin from "@/components/Login/TheLogin.vue";

import TheSelectionLogistik from "@/components/Logistik/TheSelectionLogistik.vue";
import TheLogistikInfo from "@/components/Logistik/TheLogistikInfo.vue";
import TheLogistikListe from "@/components/Logistik/TheLogistikListe.vue";
import TheLogistikPicken from "@/components/Logistik/TheLogistikPicken.vue";
import TheLogistikUmlagern from "@/components/Logistik/TheLogistikUmlagern.vue";

import { useToast } from "vue-toastification";

import { QrcodeStream } from "vue-qrcode-reader";

import dayjs from "dayjs";

import { useSound } from "@vueuse/sound";
import soundBeep from "@/assets/sounds/beep.mp3";

//import { useSound } from "@vueuse/sound";
//import soundBeep from "./assets/sounds/beep.mp3";
//import soundBeep from "./assets/sounds/error.mp3";

//import { useCounterStore } from "@/pstore";
//import { mapActions } from "pinia";

//const counterStore = useCounterStore();
//const { increment } = counterStore;
//const { count } = storeToRefs(counterStore);

//const { play } = useSound(soundBeep);

//import LoadScript from "vue-plugin-load-script";

// eslint-disable-next-line
//import * as EB from "./assets/js/ebapi.js";

//import { ref } from "vue";

//const show = ref(false);
//const click = () => {
//  show.value = !show.value;
//};
//export default {
//  name: "App",
//  methods: {},
//};
export default {
  name: "App",
  data() {
    return {
      chars: [],
      barcode: "",
      mode: "0",
      modeEinzel: "",
      browser: "",
      scanner_enable: false,
      scanner_code: new Array(),
      inputSearch: "",

      refreshing: false,
      registration: null,
      updateExists: false,

      QRScannerEnabled: false,
      QRScannerResult: "",
      QRScannerPaused: false,
      QRScannerisValid: undefined,

      geraet: "",

      scanner_vals: [
        { text: "Daniel", value: "116" },
        { text: "Marco", value: "111" },
        { text: "Christoph", value: "117" },
        { text: "Nico", value: "1" },
        { text: "Klaus", value: "107" },
        { text: "sonstiges", value: "99" },
      ],

      toast: useToast(),
    };
  },

  components: {
    TheNavbar,
    TheLogin,

    TheSelectionLogistik,
    TheLogistikInfo,
    TheLogistikListe,
    TheLogistikPicken,
    TheLogistikUmlagern,
    QrcodeStream,
  },
  computed: {
    validationSuccess() {
      return this.QRScannerisValid === true;
    },

    validationFailure() {
      return this.QRScannerisValid === false;
    },
    geraete_name() {
      return this.scanner_vals.filter(
        (geraet) => geraet.value == this.geraet
      )[0].text;
    },
    geraet_vals() {
      return Store.getters.activeGeraet_auswahl();
    },
    activeMode() {
      return Store.getters.activeMode();
    },
    scanncode() {
      console.log("APP hole daten", Store.getters.activeBarcode());

      return Store.getters.activeBarcode();
    },
    activeview() {
      console.log("APP hole View", Store.getters.activeView());

      return Store.getters.activeView();
    },
    appEventVon() {
      // prüfe ob der Zeitraum passt
      var now = dayjs().unix();
      if (Store.getters.activeEventVon() > now) {
        // Event ist noch nicht aktiv
        this.toast.warning("Event ist noch nicht aktiv!");
        this.logoutButton();
      }
      return dayjs.unix(Store.getters.activeEventVon()).format("DD.MM.YYYY");
    },
    appEventBis() {
      // prüfe ob der Zeitraum passt
      var now = dayjs().unix();
      if (now > Store.getters.activeEventBis()) {
        // Event ist abgelaufen
        this.toast.error("Event ist abgelaufen -> Logout!");
        this.logoutButton();
      }
      return dayjs.unix(Store.getters.activeEventBis()).format("DD.MM.YYYY");
    },
    appEventName() {
      return Store.getters.activeEventName();
    },
  },
  methods: {
    //...mapActions(useCounterStore, ["increment"]),

    async onDetect([firstDetectedCode]) {
      this.QRScannerResult = firstDetectedCode.rawValue;
      this.QRScannerPaused = true;

      //beep
      this.play();

      this.scanner_code["org"] = this.QRScannerResult;
      this.onBarcode({ keyCode: 9999 });

      // pretend it's taking really long
      //await this.timeout(3000);
      //this.QRScannerisValid = this.result.startsWith("http");

      // some more delay, so users have time to read the message
      await this.timeout(2000);
      this.QRScannerPaused = false;
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
    onError: console.error,
    resetValidationState() {
      this.QRScannerisValid = undefined;
    },

    created() {
      document.addEventListener("swUpdated", this.showRefreshUI, {
        once: true,
      });
      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          if (this.refreshing) return;
          this.refreshing = true;
          window.location.reload();
        });
      }
    },
    set_geraet(geraet) {
      console.log(geraet.target.value);
      this.geraet = geraet.target.value;
      localStorage.geraet = geraet.target.value;
      Store.mutations.setGeraet(geraet.target.value);
      window.location.reload();
    },
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
    closeButton() {
      window.open("", "_self").close();
    },
    reloadButton() {
      location.reload(true);
    },
    logoutButton() {
      localStorage.uuid = "";
      location.reload(true);
    },
    modusStatus(modusDO) {
      console.log("Haupt1: ", modusDO);
      //this.play();
      this.mode = modusDO.newModus;
    },
    modusStatusEinzel(modusDO) {
      console.log("Haupt2: ", modusDO);
      //this.modeEinzel = modusDO.newModus;
      this.mode = modusDO.newModus;
    },
    modusStatusBDR(modusDO) {
      console.log("Haupt4: ", modusDO);
      this.mode = modusDO.newModus;
    },
    modusSearchEinzel(modusDO) {
      console.log("Haupt3: ", modusDO);
      if (this.modeEinzel != "") {
        this.mode = this.modeEinzel;
      }
      this.inputSearch = modusDO.newSearch;
    },
    onBarcode(e) {
      //const keypressoutput = document.getElementById("pressed_keys");
      //console.log("pressed: " + e.keyCode);

      if (e.keyCode == 91) {
        //  [ Start-key from DataWedge
        //keypressoutput.innerHTML = "";
        this.scanner_enable = true;
        this.scanner_code["org"] = "";
        e.preventDefault();
      } else if (e.keyCode == 93 || e.keyCode == 9999) {
        // ] End-key from DataWedge
        //keypressoutput.innerHTML += "";
        this.scanner_code["type"] = this.scanner_code["org"].substr(0, 1);
        //console.log("TEST NICO");
        console.log(this.scanner_code["org"].split("/"));
        //console.log(this.scanner_code["org"].split("#")[0]);
        //console.log("ENDE TEST NICO");
        // wenn http://abaqr.de/XXXXX#123
        if (
          this.scanner_code["type"] == "h" &&
          this.scanner_code["org"].includes("/") &&
          this.scanner_code["org"].split("/")[2] == "qr.ba-ak.de"
        ) {
          this.scanner_code["org"] = this.scanner_code["org"].split("/");
          console.log("qrba-spezial", this.scanner_code["org"]);
          this.scanner_code["org"] = "#" + this.scanner_code["org"][3];
          this.scanner_code["type"] = this.scanner_code["org"].substr(0, 1);
        }
        // wenn https://checkinapp.datasport.de/#fd21e941-0250-4401-8072-8059da3cf056
        if (
          this.scanner_code["type"] == "h" &&
          this.scanner_code["org"].includes("#") &&
          this.scanner_code["org"].split("#")[0] ==
            "https://logistik-app.pani.cloud/"
        ) {
          this.scanner_code["org"] = this.scanner_code["org"].split("#");
          console.log("login-spezial", this.scanner_code["org"]);
          this.scanner_code["org"] = this.scanner_code["org"][1];
          this.scanner_code["type"] = "§";
        }

        this.scanner_code["gsplit"] = this.scanner_code["org"]
          .substr(1)
          .split("|");
        this.scanner_enable = false;
        console.log(this.scanner_code);
        this.browser = this.scanner_code["org"];
        // Prüfen ob Startnummernausgabe, dann erst Mode setzen
        //if (this.modeEinzel != "" && this.scanner_code["type"] == "/") {
        //  this.mode = this.modeEinzel;
        //}
        Store.mutations.setBarcode(this.scanner_code);
        //alert("gescannt");
        //this.play();

        //if (
        //  this.mode == "0" &&
        //  this.scanner_code["type"] == "/" &&
        //  this.activeview != "OrderOption"
        //) {
        //this.mode = "Einzel";
        //console.log("Modus auf Einzel setzen.... ??? WANN????");
        //}

        if (e.keyCode != 9999) {
          e.preventDefault();
        }
      } else if (this.scanner_enable) {
        // Scanner nicht ausgeben
        //keypressoutput.innerHTML += e.key;
        this.scanner_code["org"] += e.key;
        e.preventDefault();
      }
    },
  },
  created() {
    this.browser = navigator.userAgent.split(")");
    this.browser = this.browser[0].split(";");
    this.browser = this.browser[this.browser.length - 1].trim();
  },
  mounted() {
    window.addEventListener("keypress", this.onBarcode);
    if (localStorage.geraet) {
      this.geraet = localStorage.geraet;
      Store.mutations.setGeraet(this.geraet);
    }
  },
  unMounted() {
    window.removeEventListener("keypress", this.onBarcode);
  },
  setup() {
    const { play } = useSound(soundBeep);

    return {
      play,
    };
  },
};
</script>

<style>
#app {
  overflow: hidden;
}
.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  position: relative;
}
.step-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: #999999;
  transition: 0.4s;
  color: white !important;
}
.step-button[aria-expanded="true"] {
  width: 60px;
  height: 60px;
  background-color: #aa1e1e;
  color: #fff;
}
@media only screen and (max-width: 576px) {
  .step-button {
    width: 25px;
    height: 25px;
  }
  .step-button[aria-expanded="true"] {
    width: 30px;
    height: 30px;
  }
}
.done {
  background-color: #aa1e1e;
  color: #fff;
}
.step-item {
  z-index: 10;
  text-align: center;
}
#progress {
  -webkit-appearance: none;
  position: absolute;
  width: calc(100% - 20px);
  z-index: 5;
  height: 10px;
  margin-left: 18px;
  margin-bottom: 18px;
}
/* to customize progress bar */
#progress::-webkit-progress-value {
  background-color: #aa1e1e;
  transition: 0.5s ease;
}
#progress::-webkit-progress-bar {
  background-color: #444f51;
}

.font-h5-lg {
  font-size: 1.9rem;
}
@media only screen and (min-width: 992px) {
  .font-h5-lg {
    font-size: 3rem;
  }
  .font-h6-lg {
    font-size: 2rem;
  }
}
.bg-secondary {
  background-color: #999999;
}

.list-group-flush > .list-group-item {
  border-width: 0 !important;
}
.nav-link {
  color: white !important;
}

.btn {
  --bs-btn-disabled-opacity: 0.2;
}
</style>
