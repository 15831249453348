import App from "./App.vue";

import { createApp } from "vue";
import BootstrapVueNext from "bootstrap-vue-next";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

// Optional, since every component imports their Bootstrap functionality
// the following line is not necessary
// import 'bootstrap'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "./registerServiceWorker";

//import { createPinia } from "pinia";
//import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

//const pinia = createPinia();
//pinia.use(piniaPluginPersistedstate);

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

/* add icons to the library */
library.add(faUser);
library.add(faForward);
library.add(fas);

const app = createApp(App);
app.use(BootstrapVueNext);
//app.use(pinia);
app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: POSITION.TOP_CENTER,
  timeout: 1500,
});
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
